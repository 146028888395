import axios from "axios";
import cookieManager from "../managers/cookie.manager";

axios.interceptors.request.use(config => {
    config.headers['X-Requested-With'] = 'XMLHttpRequest'
    config.headers['Secret'] = process.env.REACT_APP_API_SECRET_KEY

    const token = cookieManager.getItem('accessToken');

    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`
    }

    return config;
});
