let root = `${process.env.REACT_APP_DASHBOARD_URL}/api/setup`;

module.exports = {
    root,

    user: {
        login() {
            return root + '/login';
        },

        verify() {
            return root + '/verify';
        },

        resetPassword() {
            return root + '/reset-password';
        },

        getUser() {
            return root + '/user';
        },

        getServices() {
            return root + '/services';
        },

        setServiceOptions(macAddress) {
            return root + `/${macAddress}/options`;
        },

        getVpnCountries(macAddress) {
            return root + `/${macAddress}/vpn/countries`;
        },

        getExtenders(macAddress) {
            return root + `/${macAddress}/extenders`;
        },

        updateExtenderName(macAddress) {
            return root + `/${macAddress}/extenders`;
        }
    }
}
