import * as actionTypes from './actionTypes';
import {getUser} from "../helpers/auth";
import store from "./store";
import cookieManager from "../managers/cookie.manager";

/**
 *
 * @param user
 * @returns {{payload, type: string}}
 */
export const setUser = (user) => {
    return {
        type: actionTypes.SET_USER,
        payload: user,
    };
};

/**
 *
 * @returns {(function(): void)|*}
 */
export function getUserData() {
    return () => {
        getUser().then((response) => {
            store.dispatch(setUser(response.user));
        }).catch((error) => {
            console.log('error', error)

            if (error?.response?.status === 401) {
                cookieManager.removeItem('accessToken')
            }
            store.dispatch(setUser(null));
        });
    }
}
