const cookieManager = {
    getItem(name) {
        name += '='
        const decodedCookie = decodeURIComponent(document.cookie)
        const ca = decodedCookie.split(';')
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i]
            while (c.charAt(0) === ' ') {
                c = c.substring(1)
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length)
            }
        }
        return ''
    },
    setItem(
        name,
        value,
        expiry = '',
        domain = document.location.hostname
    ) {
        if (!expiry) {
            const d = new Date()
            d.setTime(d.getTime() + 24 * 60 * 60 * 1000)
            expiry = d.toUTCString()
        } else {
            expiry = new Date(expiry).toUTCString()
        }

        const expires = 'expires=' + expiry
        document.cookie = `${name}=${encodeURIComponent(value)};${expires};path=/;domain=${domain}`
    },
    removeItem(name) {
        this.setItem(name, '', 0)
    }
}
export default cookieManager
