import axios from "axios";
import api from "../routes/api"

/**
 *
 * @param {string} email
 * @param {string} password
 * @returns {Promise<{success: boolean, access_token: string, expires: string}>}
 */
export async function signIn(email, password) {
    const response = await axios.post(api.user.login(), {
        email,
        password
    });

    return response.data
}

/**
 *
 * @param {string} email
 * @returns {Promise<{success: boolean}>}
 */
export async function forgotPassword(email) {
    const response = await axios.post(api.user.verify(), {
        email,
    });

    return response.data
}

/**
 *
 * @param email
 * @param password
 * @param token
 * @param passwordConfirmation
 * @returns {Promise<any>}
 */
export async function resetPassword(email, password,token, passwordConfirmation) {
    const response = await axios.post(api.user.resetPassword(), {
        email,
        password,
        token,
        password_confirmation: passwordConfirmation
    });

    return response.data
}

/**
 *
 * @returns {Promise<{success: boolean}>}
 */
export async function getUser() {
    const response = await axios.get(api.user.getUser());

    return response.data
}
