import "./sass/app.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import {lazy, Suspense} from "react";
import {Outlet, Route, Routes} from "react-router-dom";

const ForgotPassword = lazy(() => import("./pages/SignIn/ForgotPassword/ForgotPassword"));
const Introduction = lazy(() => import("./pages/Introduction/Introduction"));
const SignIn = lazy(() => import("./pages/SignIn/SignIn"));
const Guest = lazy(() => import("./middlewares/Guest"));
const Setup = lazy(() => import("./pages/Setup/Setup"));
const Error = lazy(() => import("./pages/Error/Error"));
const Auth = lazy(() => import("./middlewares/Auth"));
const Installation = lazy(() => import("./pages/Installation/Installation"));
const EnterpriseUpgrade = lazy(() => import("./pages/EnterpriseUpgrade/EnterpriseUpgrade"));
const Choose = lazy(() => import("./pages/Summary/Choose"));

function App() {
    return (
        <div className="App">
            <Routes>
                <Route element={<Suspense><Outlet/></Suspense>}>
                    <Route element={<Guest/>}>
                        <Route path='/' element={<Introduction/>}/>
                        <Route path='/login' element={<SignIn/>}/>
                        <Route path='/forgot-password' element={<ForgotPassword/>}/>
                    </Route>

                    <Route path='/setup' element={<Auth/>}>
                        <Route index element={<Setup/>}/>
                        <Route path=':macAddress'>
                            <Route index element={<Installation/>}/>
                        </Route>
                    </Route>

                    <Route path='/choose' element={<Auth/>}>
                        <Route index element={<Choose/>}/>
                    </Route>

                    <Route path='/enterprise-upgrade' element={<Auth/>}>
                        <Route index element={<EnterpriseUpgrade/>}/>
                    </Route>

                    <Route path='*' element={<Error/>}/>
                </Route>
            </Routes>
        </div>
    );
}

export default App;
